export default {
  '478': '验证码错误,请重新输入',
  '479': '验证码已过期，请重新输入',
  '401': '当前操作没有权限',
  '403': '当前操作没有权限',
  '400': '用户名不存在或密码错误',
  '500': '服务器内部错误,请反馈给管理员',
  '4015': '服务不可用',
  '4020': '验证码错误',
  '4026': '验证码已过期，请重新获取',
  'default': '系统未知错误,请反馈给管理员'
}
